<template>
  <div class="loading-container flex-container" :class="{ active: isShow }">
    <div class="loading-spinner-container">
      <div role="status" class="loading-text">Loading...</div>
      <svg viewBox="0 0 32 32" width="100px" height="100px">
        <circle class="spinner a" cx="16" cy="16" r="10"></circle>
        <circle class="spinner b" cx="16" cy="16" r="10"></circle>
        <circle class="spinner c" cx="16" cy="16" r="10"></circle>
      </svg>
    </div>
  </div>
  <div v-if="!isShow">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: "Loading",
  props: ["isShow"],
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/mixins";

.loading-container {
  @include fixed;
  width: 100vw;
  height: 100vh;
  justify-content: center;
  align-items: center;
  transform: scale(0);
  @include animate(1);
  @include z(1000);

  &:before {
    content: "";
    @include absolute;
    top: -100vh;
    left: -50vw;
    width: 200vmax;
    height: 200vmax;
    @include animate(1);
    border-radius: 50%;
    transform-origin: center center;
    @include bg-color(white2);
    @include z(-1);
  }

  .loading-spinner-container {
    @include relative;
    @include z(1);
  }

  &.active {
    transform: scale(1);
  }
}

.loading-text {
  @include text-disappear;
}

.spinner {
  stroke-width: 2;
  fill: none;
  stroke-linecap: round;
  stroke-dasharray: 62, 62;
  stroke-dashoffset: 62;
  animation: loading-spin 2s cubic-bezier(0.5, 0, 0.25, 1) 0s infinite;
}

.a {
  stroke: get-color(gray1);
}
.b {
  stroke: get-color(gray2);
  animation-delay: 0.25s;
}
.c {
  stroke: get-color( #1D63CF);
  opacity: 0.75;
  animation-delay: 0.5s;
}
</style>
