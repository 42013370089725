<template>
  <div class="theme-container">
    <PageContainer>
      <div class="flex-container">
        <div>
          <div class="heading">
            <p class="page-title">{{ data['header-title'] }}</p>
            <p class="page-subtitle">{{ data['header-subtitle'] }}</p>
          </div>
          <div>
            <div class="flex-container">
              <div class="flex-4 tile-wrapper">
                <router-link style="text-decoration: none" to="/summary">
                  <div class="summary tile">
                    <div class="image-wrapper">
                      <img :src="images['card1_icon.svg']" alt='image1'/>
                    </div>
                    <p class="tile-main-title">{{ data['card-1-title'] }}</p>
                    <p class="tile-subtitle-title">{{ data['card-1-description'] }}</p>
                  </div>
                </router-link>
              </div>
              <div class="flex-4 tile-wrapper">
                <router-link
                  style="text-decoration: none"
                  to="/project-spotlight"
                >
                  <div class="projectspotlight tile">
                    <div class="image-wrapper">
                      <img :src="images['card2-icon.svg']" alt='image2'/>
                    </div>

                    <p class="tile-main-title">{{ data['card-2-title'] }}</p>
                    <p class="tile-subtitle-title">{{ data['card-2-description'] }}</p>
                  </div>
                </router-link>
              </div>
              <div class="flex-4 tile-wrapper">
                <router-link style="text-decoration: none" to="/special-events">
                  <div class="special-events tile">
                    <div class="image-wrapper">
                      <img :src="images['card3-icon.svg']" alt='image3'/>
                    </div>
                    <p class="tile-main-title">{{ data['card-3-title'] }}</p>
                    <p class="tile-subtitle-title">{{ data['card-3-description'] }}</p>
                  </div>
                </router-link>
              </div>
            </div>
            <div class="flex-container">
              <div class="flex-4 tile-wrapper">
                <router-link style="text-decoration: none" to="/timeline">
                  <div class="timeline tile">
                    <div class="image-wrapper">
                      <img :src="images['card4-icon.svg']" alt='image4'/>
                    </div>
                    <p class="tile-main-title">{{ data['card-4-title'] }}</p>
                    <p class="tile-subtitle-title">{{ data['card-4-description'] }}</p>
                  </div>
                </router-link>
              </div>

              <div class="flex-4 tile-wrapper">
                <router-link style="text-decoration: none" to="/looking-ahead">
                  <div class="survey-results tile">
                    <div class="image-wrapper">
                      <img :src="images['card5.svg']" alt='image5'/>
                    </div>
                    <p class="tile-main-title">{{ data['card-5-title'] }}</p>
                    <p class="tile-subtitle-title">{{ data['card-5-description'] }}</p>
                  </div>
                </router-link>
              </div>
              <div class="flex-4 tile-wrapper">
                <router-link style="text-decoration: none" to="/slalom">
                  <div class="looking-ahead tile">
                    <div class="image-wrapper">
                      <img :src="images['card6.svg']" alt='image6'/>
                    </div>
                    <p class="tile-main-title">{{ data['card-6-title'] }}</p>
                   <img src="@/assets/images/slalom-white.svg" alt="Slalom White Logo" style="margin-top:10px"/>
                  </div>
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </PageContainer>
  </div>
</template>

<script>
import PageContainer from '@/components/PageContainer.vue';
import Loading from '@/components/Loading.vue';
// import api from '@/services/api.js';

export default {
	name: 'HomeNavigation',
	props: {
		data: {options: Object},
		images: {options: Object},
	},

	components: {
		PageContainer,
		Loading,
	},
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/mixins";

.page-subtitle {
  @include color(takeda-medium-gray);
  font-weight: 600;
  font-size: 18px;
  letter-spacing: 1px;
  margin-top: 0;
  text-transform: uppercase;
}

.page-title {
  @include color(takeda-dark-grey);
  font-weight: 600;
  font-size: 48px;
  margin-bottom: 0;
}

.tile-main-title {
  @include color(takeda-white);
  @include margin(0);
  @include margin-top(3);
  font-weight: 400;
  font-size: 18px;
  font-variant: small-caps;
  line-height: 1;
  text-transform: uppercase;
}

.tile-subtitle-title {
  @include text-big;
  @include color(takeda-white);
  @include margin(0);
  font-size: 36px;
}

.tilesRow1 {
  margin-bottom: 35px;
}
.heading {
  padding: 0 1em;
  text-align: center;
}
.tile-wrapper {
  padding: 1em;
  .tile {
    @include padding(4);
    @include animate;
    text-align: center;
    @include center;
    box-shadow: 0px 4px 8px rgba(208, 215, 217, 0.4);
    border-radius: 4px 4px 40px 4px;
    min-width: 250px;
    min-height: 200px;
    &:hover {
      @include opacity(0.7);
    }
    @include responsive(phone) {
      width: auto;
    }
    .image-wrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 90px;
    }
  }
}

.logo {
  @include margin(3);
}

.summary {
  background-color: #1D63CFB2;

}

.projectspotlight {
  background-color: #1D63CF;

}

.special-events {
  background-color: #134FAD;

}

.timeline {
  background-color: #0F408C;

}

.survey-results {
  background-color:#082A60;

}

.looking-ahead {
  background-color: #041B3F;

}

.flex-container {
  justify-content: space-around;

  @include responsive(tablet) {
    flex-wrap: wrap;
  }
  @include responsive(phone) {
    flex-wrap: wrap;
  }
}
</style>
