<template>
  <div class="page-container"><slot></slot></div>
</template>

<script>
export default {
  name: "PageContainer",
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/mixins";

.page-container {
  @include centerize;
  @include relative;
}
</style>
