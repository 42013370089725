import axios from 'axios';

export default {
	async authUser(email,password) {
		return await axios.post(`/auth`, {email: email, password: password});
	},

	async getContent(id) {
		return await axios.get(`/content/${id}`);
	},
}
