<template>
<div class="alert-container" :class="[isActive ? 'active' : '', type]">
	<PageContainer>
		<div role="alert" class="alert-content">
			<img src="@/assets/images/warning.svg" alt="Warning Icon" />
			<div class="alert-message">
				<div class="intro">Something went wrong. Please try again later.</div>
				<div class="details"><slot></slot></div>
			</div>
		</div>
	</PageContainer>
</div>
</template>

<script>
import PageContainer from '@/components/PageContainer.vue';
export default {
	props: {
		isActive: {
			type: Boolean,
			default: false
		},
		type: {
			type: String,
			default: 'error'
		},
		message: String
	},

	components: {
		PageContainer
	}
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/mixins';

.alert-container {
	@include bg-color(white2, .5);
	margin-top: 10%;
	width: 100%;
	@include z(9);
	@include text(heading3);
	@include text-center;
	@include color(black);
	@include animate;
	overflow: hidden;
	height: 0;

	&.active {
		height: auto;
		@include padding(5 0);
	}

	.alert-message {
		@include margin-top;
	}
}

.details {
	@include text(small);
	line-height: 2;
}

</style>