<template>
  <Alert :isActive="isError" v-if="isError">{{errorMessage}}</Alert>
  <Loading :isShow="isLoading" v-if="!isError">
    <!-- Themes -->
    <Navigation></Navigation>
    <div class="top-logo-container">
      <img
        :src="images['header-logo-2.svg']"
        alt="Client-Slalom White Logo"
      />
    </div>
    <HomeNavigation :data="content" :images="images"></HomeNavigation>
  </Loading>
</template>

<script>
import api from '@/services/api.js';
import Loading from '@/components/Loading.vue';
import Navigation from '@/components/Navigation.vue';
import HomeNavigation from '@/components/HomeNavigation.vue';
import Alert from '@/components/Alert.vue';

export default {
	data: () => ({
		content: {},
		images: [],
		isLoading: false,
		isError: false,
		errorMessage: '',
	}),
	methods: {
		async getData() {
			this.isError = false;
			this.isLoading = true;
			try {
				const { data } = await api.getContent('home');
				this.content = data.content;
				this.images = data.images;
			} catch (err) {
				this.isError = true;
				this.errorMessage = err.message;
			} finally {
				this.isLoading = false;
			}
		},
	},

	async beforeRouteUpdate(to, from, next) {
		await this.getData();
		next();
	},

	async created() {
		await this.getData();
	},

	components: {
		Loading,
		Navigation,
		HomeNavigation,
		Alert,
	},
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/mixins";
.top-logo-container {
  @include bg-color(takeda-light-grey);
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
