<template>
<footer>
	<ul class="footer-container-list">
		<li><img src="@/assets/images/slalom-white.svg" alt="Slalom White Logo" /></li>
		<li>&copy;2021 Slalom, LLC. &mdash; All Rights Reserved.</li>
		<li><a href="https://www.slalom.com/privacy-policy">Privacy Policy</a></li>
		<li><a href="https://www.slalom.com/terms-of-use">Terms of Use</a></li>
		<li><a href="https://slalom.com">Slalom</a> | <a href="https://slalom.com/locations/chicago">Slalom Chicago</a></li>
	</ul>
</footer>
</template>

<script>

export default {
	name: 'Footer',
	components: {
	}
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/mixins';

footer {
	min-height: 1vh;
	@include bg-color(takeda-dark-grey);
	@include padding(2);
	@include margin-top(10);
	@include text(small);
	line-height: 2
}

.footer-container-list {
	display: flex;
	justify-content: space-around;
	flex-wrap: wrap;
	align-items: center;

	@include responsive(phone) {
		flex-wrap: wrap;
	}

	@include responsive(tablet) {
		flex-wrap: wrap;
	}

	li {
		@include responsive(phone) {
			flex: 1 0 50%;
		}

		@include responsive(tablet) {
			flex: 1 0 50%;
		}

		@include responsive(desktop) {
		}
		list-style-type: none;
	}
}
</style>